import { Link as ReactLink } from "@reach/router";
import React from "react";
import { Link as RebassLink, Text } from "rebass";
import styled from "styled-components";

const BaseLink = styled(RebassLink)`
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: ${props => props.underline !== false && "underline"};
  }

  ${props => props.css};
`;

const UniversalLink = props => {
  const href = props.href || "";
  const external = (href.indexOf("//") !== -1)  || href.indexOf("mailto") === 0;

  // target={external ? "_blank" : undefined}
  return (
    <BaseLink
      target={props.target || undefined}
      rel={external ? "noopener" : undefined}
      as={!external ? ReactLink : RebassLink}
      to={href}
      {...props}
    />
  );
};

export const MoreLink = ({to, children, ...props}) => (
  <Text {...props} color="primary" fontSize={1}><UniversalLink to={to}>{children} _____</UniversalLink></Text>
)

export default styled(UniversalLink)``;
