import { useQuery } from '@apollo/react-hooks';
import HeaderToggle from "components/HeaderToggle";
import Page from "components/Page";
import { sanitize } from 'dompurify';
import { GET_PAGE_BY_SLUG } from "Globals";
import parseHTML from 'html-react-parser';
import { parseOptions } from 'modules/WordpressContent';
import React, { useState } from "react";
import { Box } from "rebass";
import Loader from "./Loader";


const PAGES = {
  'Forschungen': 'stadtforschung-forschungen',
  'Methoden': 'stadtforschung-methoden',
  'Publikationen & Vorträge': 'stadtforschung-praesentationen'
}

export default function StadtforschungPage(props) {
  const [slug, setSlug] = useState(Object.values(PAGES)[0]);

  const pageTopData = useQuery(GET_PAGE_BY_SLUG, { variables: { slug: 'stadtforschung' } });
  const pageData = useQuery(GET_PAGE_BY_SLUG, { variables: { slug } });

  return (
    <Page>
      {parseHTML(sanitize(pageTopData?.data?.page?.content), parseOptions())}
      <HeaderToggle items={Object.keys(PAGES)} onSelect={e => setSlug(PAGES[e])} />
      {pageData.loading ? <Loader /> :

        <Box mt={4} mx={[1, 6]}>
          {parseHTML(sanitize(pageData?.data?.page?.content), parseOptions())}
        </Box>
      }
    </Page>
  )
}

