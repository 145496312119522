import React, { useState } from "react";
import { Minus, Plus } from "react-feather";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import Icon from "./Icon";
import Text from "./Text";


const AccordionWrapper = styled(Flex)`
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.colors.backgroundSecondary};
  }
  transition: all 0.4s;
`
const Content = styled(Box)`
  opacity: ${props => (props.open ? "1" : "0")};
  max-height: ${props => (props.open ? "100%" : "0")};
  overflow: hidden;
  padding: ${props => (props.open ? "15px" : "0 15px")};
  transition: all 0.4s;
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const Accordion = ({header, isOpen=false, children, ...props}) => {

  const [open, setOpen] = useState(isOpen);

  return <Box>
      <AccordionWrapper justifyContent={["space-between"]} alignItems="center" py={2} px={3} onClick={_ => setOpen(!open)}>
      <Text color="tertiary" fontSize={[2,3]} pr={5} py={2} fontWeight={open ? "bold" : "normal"}>{header}</Text>
                  <Icon color="tertiary">
          {open ? <Minus /> : <Plus  />}
                  </Icon>
      </AccordionWrapper>

      <Content open={open} p={2}>
          {children}
      </Content>
    </Box>

}

export default Accordion;

