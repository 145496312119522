import React from "react";
import { Box } from "rebass";
import styled from "styled-components";

const WideSection = styled(Box)`
`;

export default (props) => (
  <WideSection
  css={{
    width: '100vw',
    position: 'relative',
    left: 'calc(50% - 8px)',
    marginLeft:'-50vw',
  }}
    {...props}
  />
);
/*
    width={["100%", "150%", "200%"]}
    ml={[0, "-25%", "-50%"]}
    */
