import React from "react";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import Footer from "./Footer";
import Head from "./Head";
import Layout from "./Layout";
import Nav from "./Nav";


//https://plugins.trac.wordpress.org/browser/gutenberg/tags/10.1.1/build/block-library/style.css
const GlobalStyle = createGlobalStyle`
  ${reset}

  body {
    background-color: ${props => props.theme.colors.background};
    margin: 0;
    font-family: ${props => props.theme.fonts.system};
  }

  strong {
    font-weight: bold;
  }

  hr {
    margin: 1em 0;
    border-color: rgba(0, 0, 0, 0.1);
  }
`;
const NAV_HEIGHT = 135;

export const DEFAULT_TITLE = "space and place";
const DEFAULT_DESCRIPTION = "space and place";

export default function Homepage ({wide=false, ...props}) {
  return (
        <>
          <Nav />
           <Head
              title={DEFAULT_TITLE}
              description={DEFAULT_DESCRIPTION}
            />

          <Layout
            wide={wide}
            pt={[`${NAV_HEIGHT}px`]}//, `${NAV_HEIGHT / 1}px`]}
              css={{
                paddingLeft: "8px",
                paddingRight: "8px"
              }}

            >
    <GlobalStyle />

      {props.children}

          </Layout>


    <Footer />
        </>
  )
}
