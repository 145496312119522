import Image from "components/Image";
import ImageLoader from "components/ImageLoader";
import Link from "components/Link";
import Text from "components/Text";
import { getDay } from "date-fns";
import { format, parseISO, URL_PREFIX } from 'Globals';
import React, { useState } from "react";
import { Box, Flex, Heading } from "rebass";
import styled, { css } from "styled-components";



const TitleText= styled(Text)`
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

const EventListItem = ({ evt, last}) => {

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const {uri, headerimage, eventStart, eventEnd, title} = evt;

  return (
    <Link
    href={uri}
    mt={3}
    pb={3}
    css={css`
      width: 100%;
      display: block;
      ${!last && "border-bottom: 2px solid rgba(0, 0, 0, 0.1);"} &:hover {
        text-decoration: none;
        ${Heading} {
          text-decoration: underline;
        }
      }
    `}
  > 
    <Flex flexDirection={["column", "row"]} alignItems={["center", "flex-start"]} justifyContent={["center", "flex-start"]}>
      <Box px={4}>
      {(!loaded || error) && 
        <ImageLoader width={280} />
      }
      <Image 
        style={loaded ? {} : { display: 'none' }}
        width={[280]} 
      borderRadius='0.5em'
      css={`
        transition: all 0.3s ease 0s;
        &:hover {  transform: scale(1.02);}
        `}
        src={`${URL_PREFIX}${headerimage}`} 
        onLoad={_ => setLoaded(true)}
        onError={_ => setError(true)}
      />
    </Box>
      <Box>
      <TitleText fontWeight={700} mt={4} fontSize={3} mb={2} textAlign={["center", "left"]} >
        {title}
      </TitleText>
      <Text
        color="tertiary"
        m={0}
        css={{
          height: "1em"
        }}
        fontSize={2}
      >
            {getDay(parseISO(eventStart)) !== getDay(parseISO(eventEnd))  ?
              <>
              {format(parseISO(eventStart), "d. MMM yyyy | HH:mm", )} -<br/> {format(parseISO(eventEnd), "d. MMM yyyy | HH:mm")}</>
              :
              <>
              {format(parseISO(eventStart), "d. MMM yyyy | HH:mm")} - {format(parseISO(eventEnd), "HH:mm")}
              </>
            }
        {/*`${format(parseISO(evt.eventStart), "d. MMM yyyy | HH:mm", {locale: deLocale}).toUpperCase()} - 
          ${format(parseISO(evt.eventEnd), "HH:mm", {locale: deLocale}).toUpperCase()}`*/}
      </Text>
      </Box>
    </Flex>
  </Link>
          )
};
export default EventListItem; 
