import { H2, P } from "components/Heading";
import Link from "components/Link";
import React from "react";
import { Box, Flex } from "rebass";

export default function ProjectIdea(props) {
  return <Flex justifyContent="center" px={4} {...props}>
        <H2 py={2}>
        <Box as="span" pl={[0,0]}>Du hast</Box>
        <br />
        <Box as="span" pl={[0,4]}>eine <P>Idee</P> für</Box> <P><Link href="mailto:raumgestaltung@spaceandplace.at"><Box as="span" pl={3} fontSize={2}>raumgestalung@spaceandplace.at</Box></Link></P>
        <br />
        <Box as="span" pr={[0,1]}>den <P>öffentlichen</P></Box>
        <br />
        <Box as="span" pl={[0,5]}>Raum?</Box>
      </H2>
    </Flex>
}
