import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import { TextButton } from "./Button";
import Icon from "./Icon";
import Link from "./Link";

const BreadcrumbLinkWrapper = styled(Box)`
  ${Icon} {
    transition: transform 50ms ease-in-out;
  }

  &:hover ${Icon} {
    transform: translateX(-4px);
  }
`;

type Props = {
  href: string,
  children: React$Node,
  mt?: number,
  color?: string
};

const BreadcrumbLink = (props: Props) => (
  <BreadcrumbLinkWrapper mt={props.mt}>
    <TextButton as={Link} Bhref={props.href} underline={false}>
      {props.children}
    </TextButton>
  </BreadcrumbLinkWrapper>
);

export default BreadcrumbLink;
