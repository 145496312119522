import { useQuery } from '@apollo/react-hooks';
import HeaderToggle from "components/HeaderToggle";
import Heading, { H4 } from "components/Heading";
import Link from "components/Link";
import Page from "components/Page";
import Text from "components/Text";
import WideSection from "components/WideSection";
import { formatISO, parse } from "date-fns";
import { sanitize } from 'dompurify';
import { GET_PAGE_BY_SLUG } from "Globals";
import parseHTML, { attributesToProps, domToReact } from 'html-react-parser';
import DownloadDocuments from "modules/DownloadDocuments";
import PressContact from "modules/PressContact";
import React, { useState } from "react";
import { Box } from "rebass";
import Loader from "./Loader";
import PressListItem from "./PressListItem";







export const parseOptions = (numItems = -1) => {
  let itemsCounter = 0;
  return {
    replace: ({ name, attribs, type, data, children, parent, ...domNode }) => {

      if (type === 'text' && parent && parent.name === 'li') {

        itemsCounter += 1;
        if (numItems != -1 && numItems < itemsCounter) {
          return <></>;
        }

        const _data = data.split('|')
        if (_data.length != 2) return; // reduce parsing errors

        const props = attributesToProps(domNode.next.attribs);

        let { href } = props;
        if (!href.includes('cms')) {
          href = href.replace(/http(s?):\/\/spaceandplace\.at\//g, '')
        }
        const link = <Text color="primary">
          <Link {...props} href={href} target="_blank">{domToReact(domNode.next.children, parseOptions(numItems))}</Link>
        </Text>
        const item = {
          date: formatISO(parse(_data[0].trim(), 'dd.MM.yyyy', new Date())),
          press: _data[1].trim()
        }

        return <PressListItem key={item.date} item={item}>
          {link}
        </PressListItem>
      }
      if (name === 'a') return <></>
    }
  }
};

const PAGES = {
  'Berichterstattung': 'medienberichte',
  'Pressecorner': 'pressecorner'
}

export default function PressePage(props) {
  const [slug, setSlug] = useState(Object.values(PAGES)[0]);
  const [numItems, setNumItems] = useState(-1);

  const pageData = useQuery(GET_PAGE_BY_SLUG, { variables: { slug } });

  return (
    <Page>
      <Heading>
        <Box as="span">In the</Box>
        <br />
        <Box as="span" color="primary" pr={6} Bcss={{ paddingRight: '20%' }}>media</Box>
      </Heading>
      <HeaderToggle items={Object.keys(PAGES)} onSelect={e => setSlug(PAGES[e])} />
      {pageData.loading ? <Loader /> :

        <Box mt={4} mx={[1, 6]}>
          {parseHTML(sanitize(pageData.data.page.content), parseOptions(numItems))}
        </Box>
      }
      {slug === 'medienberichte' && <H4 color="primary"><Link href="/medienberichte-archiv">zum Archiv</Link></H4>}

      {slug === 'pressecorner' && <PressContact />}
      <WideSection>
        <DownloadDocuments backgroundColor="backgroundSecondary" my={5} showPhotos={slug === 'pressecorner'} />
      </WideSection>
    </Page>
  )
}
