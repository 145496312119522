import { Redirect, Router } from "@reach/router";
import React from "react";
import { ThemeProvider } from "styled-components";
import AboutUs from './pages/AboutUs';
import Home from './pages/Home';
import Legacy from './pages/Legacy';
import Presse from './pages/Presse';
import Projekte from './pages/Projekte';
import Stadtforschung from './pages/Stadtforschung';
import Termine from './pages/Termine';
import Newsletter from './pages/Newsletter';
import Test from './pages/Test';
import redirects from './redirects';
import theme from './theme';

//console.log(Object.entries(redirects))
function App() {

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Home path="/" />
        <AboutUs path="ueber-uns" />
        <Presse path="presse" />
        <Projekte path="projekte" />
        <Termine path="termine" />
        <Newsletter path="newsletter" />
        <Stadtforschung path="stadtforschung" />
        <Test path="test" />
        <Legacy path="/*" />

        <Redirect from="index.html" to="/" />
        {Object.entries(redirects).map(([k, v]) => <Redirect from={k} to={v} />)}
      </Router>
    </ThemeProvider>
  );
}

export default App;

