import Heading, { H2, P } from "components/Heading";
import Image from "components/Image";
import Page from "components/Page";
import Paragraph from "components/Paragraph";
import Team from "modules/Team";
import React from "react";
import { Box, Flex } from "rebass";
import topImage from "./top.jpg";





export default function AboutUsPage (props) {
  return (
        <Page>
    <Heading textAlign={["left", "center"]} mb={1}> 
        <Box as="span" pl={[0, 150]}>Über</Box> 
        <br />
        <Box as="span" color="primary" pl={[0, 210]}>uns</Box> 
    </Heading>

    <Flex flexDirection={["column", "row"]} mt={2} alignItems="flex-start">
      <Image src={topImage} width={["auto", 350, 500]} pr={[0, 4]} mt={[0, -110]} mb={[0, 70]} />
      <Box>
      <Paragraph fontWeight="700">
<P>Wir erwecken</P> den öffentlichen Raum zum Leben. Dazu begeben wir uns an oftmals unscheinbare und <P>wunderschön-hässliche Orte</P> und bespielen sie gemeinsam mit Anrainer*innen und lokalen Initiativen.
      </Paragraph>
      <Paragraph fontWeight="700">
    Mittels künstlerischer Interventionen, sozio-kultureller Projekte und kreativer Aneignung schaffen wir <P>soziale Räume</P> und bringen Menschen miteinander ins <P>Gespräch</P>. 
      </Paragraph>
    </Box>

    </Flex>
      <H2>
        <Box as="span">Unsere <P>Bühne</P></Box>
        <br/>
        <Box as="span" pr={[0, 200]}>ist <P>die Stadt</P></Box> 
        <br/>
        <Box as="span">die Akteur*innen</Box> 
        <br/>
        <Box as="span" pl={[0,200]}>sind <P>ihre Bewohner*innen.</P></Box> 
      </H2>
      <Paragraph columns={2} centered={false}>
    Unsere Arbeit ist prozessorientiert, forschend und vernetzend. Wir schaffen Begegnungen, machen Verborgenes sichtbar, entfalten Schönes, irritieren und überraschen. Unser Ziel sind unmittelbar erreichbare Wohlfühlorte und Klimaoasen im urbanen Raum. Unser Ansatz ist ebenso künstlerisch wie partizipativ.
    <br/>
    <br/>
Seit fünfzehn Jahren agieren wir als Pionier*innen, Initiator*innen und Kooperationspartner*innen. Wir entwickeln Prototypen urbaner Nutzung und ermöglichen Empowerment. Wir sind ein Design-Labor für neues Stadtleben.
    <br/>
    <br/>
    <strong>
    Wir von space and place sind davon überzeugt, dass künstlerische und partizipative Interventionen unsere Stadt nachhaltig zum Positiven hin verändern – als Plätze an sich und als Orte der Begegnung.
</strong>
    </Paragraph>


    <Team />
    {/* <Sponsors /> */}

    </Page>
  )
}
