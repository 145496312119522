import React from "react";
import { Box } from "rebass";
import styled from "styled-components";

const Iframe = styled(Box)`
`;

export default (props) => (
  <Iframe
    as="iframe"
    {...props}
  />
);
