import { useQuery } from '@apollo/react-hooks';
import Accordion from "components/Accordion";
import Head from "components/Head";
import Heading, { H2, H4, P } from "components/Heading";
import Image from "components/Image";
import WohnstrassenMap from "components/Maps/Wohnstrassen";
import WPComponents from "components/wordpress";
import { getDay } from "date-fns";
import findDeep from 'deepdash-es/findDeep';
import { format, GET_PAGE_BY_SLUG, GET_PAGE_META_BY_SLUG, parseISO, URL_PREFIX } from "Globals";
import parseHTML, { attributesToProps, domToReact } from 'html-react-parser';
// TODO maybe implement custom components registry in future
import Contact from "modules/Contact";
import ImpressumMap from "modules/ImpressumMap";
import React from "react";
import { Box, Flex } from "rebass";
import Carousel from 'styled-components-carousel';
import Loader from "./Loader";










export const parseOptions = () => {
  return {
    replace: ({ name, attribs, children, ...domNode }) => {
      const props = attributesToProps(attribs);

      if (name) {
        if (!WPComponents[name]) {
          console.error(`No component was found for ${name}`)
          return <></>
        }
        if (props?.className?.includes('sp-impressum-map')) {
          return <Box>
            <ImpressumMap wide={false} />
          </Box>
        }

        if (props?.className?.includes('sp-newsletter')) {
          return <Box>
            <Contact wide={false} />
          </Box>
        }
        if (props?.className?.includes('sp-wohnstrassenkarte')) {
          return <Box css={{ height: "500px" }}>
            <WohnstrassenMap />
          </Box>
        }
        if (props?.className?.includes('sp-slider')) {
          // old versus new Wordpress API
          const c = children[0].name === 'ul' ? children[0].children : children.filter(c => c.type === 'tag')
          return <Carousel center infinite>
            {
              c.map(ci => {
                const Component = WPComponents[ci.name];
                return <Component {...props} style={{ justifyContent: 'center', padding: '5%' }}>{domToReact(ci.children, parseOptions())}</Component>
              })
            }
          </Carousel>
        }
        if (props?.className?.includes('wp-block-pb-accordion-item')) {
          const titleEl = findDeep(children, (value, key, parent, ctx) => {
            return value?.attribs?.class?.includes('c-accordion__title')
          }, { childrenPath: 'children' })

          const title = titleEl?.value?.children[0]?.data;

          const Component = WPComponents[name];
          return <Accordion header={title}>
            <Component {...props}>{domToReact(children[1]?.children, parseOptions())}</Component>
          </Accordion>
        }


        const Component = WPComponents[name];
        if (children.length > 0) {
          return <Component {...props}>{domToReact(children, parseOptions())}</Component>
        } else {
          return <Component {...props} />
        }
      }
    }
  }
}


export default function WordpressContent({ slug, ...props }) {

  // we need to try both options
  const pageData = useQuery(GET_PAGE_BY_SLUG, { variables: { slug } });
  const pageMetaData = useQuery(GET_PAGE_META_BY_SLUG, { variables: { slug } });

  const loading = pageData.loading || pageMetaData.loading;
  const error = pageData.error || pageMetaData.error;
  let data = {}
  if (!loading) {
    if (pageMetaData.data) {
      //console.log('pageMetaData', pageMetaData)
      // if it is a redirect than this contains the data otherwise the above
      if (pageMetaData.data.pages.edges.length === 1) {
        data = pageMetaData.data.pages.edges[0].node
      }
    }
    if (pageData.data) {
      //console.log('pageData', pageData)
      data = pageData.data.page;
    }
  }

  const formatHeader = (title) => {

    const parts = title ? title.split(' ') : [' ', ' '];
    return <Heading>{parts.slice(0, -1).join(' ')} <P>{parts.pop()}</P></Heading>
  }

  const hasHeader = data && (data.content ? (data.content.includes('<h1') || data.content.includes('<h2')) : false);
  const hasHeaderImage = data && (data.content ? data.content.includes('wp-block-image') : false);

  return (
    <>
      {loading ? <Loader /> :
        <>
          {data ?
            <>
              <Head
                title={data.title}
                description={data.title}
              />

              {/* HACK */}
              {!hasHeader && <>{formatHeader(data.title)}</>}
              {(!hasHeader && data.headerimage) ?
                <Flex my={2} justifyContent={["flex-start", "center"]} flexDirection={["column", "row"]} alignItems="flex-start">
                  <Image width={['auto', 300, 400]} src={`${URL_PREFIX}${data.headerimage}`} mt={[0, 4]} p={[2, 3]} flexBasis="33%" />

                  <Box>
                    {data.eventStart && data.eventEnd &&
                      <Box pb={[1, 3]}>
                        {getDay(parseISO(data.eventStart)) !== getDay(parseISO(data.eventEnd)) ?
                          <H4 textAlign="left">{format(parseISO(data.eventStart), "d. MMM yyyy | HH:mm")} - {format(parseISO(data.eventEnd), "d. MMM yyyy | HH:mm")}</H4>
                          :
                          <H4 textAlign="left">{format(parseISO(data.eventStart), "d. MMM yyyy | HH:mm")} - {format(parseISO(data.eventEnd), "HH:mm")}</H4>
                        }
                      </Box>

                    }
                    {data.content && parseHTML(data.content, parseOptions())}
                  </Box>
                </Flex>
                :
                  <>{parseHTML(data.content, parseOptions())}</>
              }
                  {/*(hasHeader || hasHeaderImage) && data.content && parseHTML(data.content, parseOptions())*/}

            </>
            :
            <H2>404 - Seite nicht gefunden...</H2>
          }
        </>
      }
    </>
  );
}
