import React from "react";
import { Facebook, Instagram, Youtube } from "react-feather";
import { Box, Flex, Image } from "rebass";
import styled from "styled-components";
import logo from '../../logo.png';
import Divider from "../Divider";
import Icon from "../Icon";
import Layout from "../Layout";
import Link from "../Link";
import Paragraph from "../Paragraph";
import Text from "../Text";

const FooterColumn = (props: {
  title: string,
  width?: number,
  last?: boolean,
  children: React$Node
}) => (
    <Flex flexDirection="column" flex={1} mb={4} mr={props.last === true ? 0 : 4}>
      {props.children}
    </Flex>
  );

type ListItemProps = {
  Icon?: React$ComponentType<{ size: string }>,
  children: React$Node,
  href: string,
  rel?: string
};

const FooterListItem = ({
  Icon: IconComp,
  rel,
  children,
  href
}: ListItemProps) => (
    <Link href={href} rel={rel}>
      <Text py={1} color="inherit" css={{ display: `block` }}>
        {IconComp && (
          <Icon mr={2} ml={0}>
            <IconComp size="1.3em" />
          </Icon>
        )}
        {children}
      </Text>
    </Link>
  );
const Wrapper = styled(Flex).attrs({
  as: "footer"
})`
  background: ${props => props.theme.colors.backgroundSecondary};
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export default (props: {}) => (
  <Wrapper
    py={5}
    mt={3}
  >
    <Layout wide={true} width={[1, 0.8, 0.6]}>
      <Flex flexDirection={["column", "row"]}>
        <FooterColumn>
          <Image src={logo} css={{ width: '144px' }} />
        </FooterColumn>
        <FooterColumn>
          <Paragraph lineHeight={1.3} fontSize={2}>
            <strong>space and place</strong> - kulturelle Raumgestaltung<br />
          Weidmanngasse 27/3<br />
          A-1170 Wien<br />
          Büro: Kriemhildplatz 10, A-1150 Wien
          </Paragraph>
        </FooterColumn>
      </Flex>
      <Flex flexDirection={["column", "row"]} >
        <FooterColumn>
          <Flex flexDirection={"row"} alignItems="center" justifyContent="space-evenly">
            <FooterListItem Icon={Facebook} href="https://facebook.com/spaceandplace" />
            <FooterListItem Icon={Instagram} href="https://instagram.com/spaceandplace_at" />
            <FooterListItem Icon={Youtube} href="https://youtube.com/spaceandplace" />
          </Flex>
        </FooterColumn>
        <FooterColumn >
          <Text color="primary" fontWeight="bold" fontSize={1} css={{ wordBreak: 'break-all' }}>
            <Link href="mailto:raumgestaltung@spaceandplace.at">raumgestaltung@spaceandplace.at</Link></Text>
        </FooterColumn>
      </Flex>
      <Flex flexDirection={["column", "row"]} mt={2} p={[0, 3]}>
        <FooterColumn>
          <Paragraph lineHeight={1.3} fontSize={3}>
          Du möchtest unsere <Box as="span" color="primary">Arbeit unterstützen?</Box> {` `}
          Wir nehmen gerne <Box as="span" color="primary">Spenden entgegen.</Box>
          </Paragraph>
          <Paragraph lineHeight={1.3} fontSize={2}>
          space and place - kulturelle Raumgestaltung<br/>
          IBAN: AT58 2011 1297 2890 2500 <br />
          BIC: GIBAATWWXXX
          </Paragraph>
        </FooterColumn>
      </Flex>
      <Divider />

      <Flex flexDirection={["column", "row"]} justifyContent="center" mt={2}>
        <FooterColumn>
          <FooterListItem href="/newsletter" >
            Newsletter
        </FooterListItem>
          <FooterListItem href="/impressum" >
            Impressum
        </FooterListItem>
          <FooterListItem href="/datenschutz">
            Datenschutz</FooterListItem>
        </FooterColumn>

        <Text color="secondary">
          © {new Date().getFullYear()} space and place
        </Text>
      </Flex>
    </Layout>
  </Wrapper>
);
