import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import Link from "./Link";
import Text from "./Text";

const Wrapper = styled(Box)`
  text-transform: uppercase;
  width: ${props => `${props.width}`};
  position: relative;
  z-index: 1;

  ${props => props.centered && `margin: 0 auto;`} 

  ${Link} {
    color: ${props => props.theme.colors.primary};
  }
`;

type Props = {
  ...$Exact<RebassProps>,
  centered?: boolean,
};

export default ({ centered, width='100%', as, ...props }: Props) => (
  <Wrapper centered={centered} width={width} as={as}>
    <Text color="#333" mb={3} fontSize={[2,2]} lineHeight={[1, 1.2]} {...props} />
  </Wrapper>
);
