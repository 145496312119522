import { H2 } from "components/Heading";
import Image from "components/Image";
import Subtitle from "components/Subtitle";
import React from "react";
import { Box, Flex } from "rebass";
import Form from "./Form";
import photo from "./img.jpg";



export default function({wide=false, ...props}) {
  return <Flex {...props} flexDirection={["column", "row"]} justifyContent={"center"} p={4}>
    <Box>
      <H2 textAlign={["left", "center"]} py={2}>
        <Box as="span" pl={[0, 4]}>Stay</Box>
        <br />
        <Box as="span" pr={[0, 6]} color="primary">updated.</Box>
        <br/>
        <Box as="span" pl={[0, 4]}>Always.</Box>
      </H2>
    <Subtitle width={["auto", '100%']} textAlign={["left"]} mt={[0, -150]} ml={[0, '60%']}>
      Wir informieren Dich im Rahmen unseres Newsletters über unsere Aktivitäten .
    </Subtitle>

      <Flex flexDirection={['column', 'row']}>
        <Image src={photo} width={['auto',  wide ? 650:550]}  mr={[0, 3]} mt={[0, -1]} mb={[0, 7]} css={{zIndex: '-1'}}/>
          <Form ml={[0,-6]} mt={[0, 7]} mb={[0, 6]} />
      </Flex>
    </Box>
    </Flex>
}
