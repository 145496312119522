import Button from "components/Button"
import Text from "components/Text"
import React, { useState } from "react"
import slugify from "react-slugify"
import { Box, Flex } from "rebass"
import styled, { css } from "styled-components"


const CONTACT_ACTION_URL = `https://spaceandplace.us13.list-manage.com/subscribe/post?u=1f77e18664b8fea9629e90297&amp;id=b1a8d79e58`

const firstnameInputBlock = {
    name: "FNAME",
    label: "Vorname",
    inputType: "text",
    autocomplete: "given-name",

}
const lastnameInputBlock = {
    name: "LNAME",
    label: "Nachname",
    inputType: "text",
    autocomplete: "family-name",
}

 const emailInputBlock = {
    name: "EMAIL",
    label: "Deine E-Mail-Adresse",
    inputType: "text",
    autocomplete: "email",
   wide: true,
}

const fields = [
        emailInputBlock,
        firstnameInputBlock,
        lastnameInputBlock,
]
const Wrapper = styled(Box)`
  background: ${props => props.theme.colors.backgroundSecondary};
`;

export default (props) => {

  const [agree, setAgree] = useState(false);
  return (
    <Wrapper {...props} p={4}>
      <StyledForm
        as="form"
        name="contact"
        action={CONTACT_ACTION_URL}
        method="POST"
        target="_blank"
        novalidate
      >
        {fields.map(field => {
            return (
              <FormField wide={field.wide} key={slugify(field.label)}>
                <input
                  id={slugify(field.label)}
                  name={field.name}
                  type={field.inputType}
                  placeholder={field.label}
                  autoCorrect="off"
                  autoComplete={field.autocomplete | ``}
                />
              </FormField>
            )
        })}
          <FormField>
            <Button type="submit" disabled={!agree} value="Submit"><strong>Abonnieren</strong></Button>
          </FormField>
          <FormField wide>
            <Flex alignItems="flex-start">
            <input
              id={slugify("agree")}
              name={slugify("agree")}
              type="checkbox"
              checked={agree}
              onChange={e => setAgree(e.target.checked)}
            />
            <label htmlFor={slugify("agree")}>
<Text pl={3}>  {`Mit der Eingabe meiner E-Mail-Adresse und dem Anklicken des Buttons Abonnieren erkläre ich mich damit einverstanden,
    dass mir space and place regelmässig Informationen per E-Mail zusendet. Ich kann meine Einwilligung jederzeit 
    widerrufen. Weitere Informationen finden Sie in der Datenschutzerklärung.
  `}
      </Text></label>
    </Flex>
          </FormField>
      </StyledForm>
  </Wrapper>
  )
}


 const StyledForm = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1.5rem;
  justify-items: stretch;

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr;
  }
`

 const FormField = styled(Box)`
  input {
    position: relative;
    line-height: 2.25rem;
    font-size: 1rem;
    padding: 0 0.625rem;
    border: none;
    border-bottom: 2px solid ${props => props.theme.colors.black};
    transition: box-shadow 150ms ${props => props.theme.easing};
    color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.backgroundSecondary}

    &:focus {
      outline: none;
      box-shadow: none;
    }

  }

  ${p =>
    p.wide &&
    css`
      @media (min-width: ${props => props.theme.breakpoints[1]}) {
        grid-column-start: 1;
        grid-column-end: 4;
      }
    `};
    input[type=text] {

    width: 100%;
    }
`
/*
 
  input[type=checkbox] {
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
    outline: 2px solid ${props => props.theme.colors.black};
  }
  */
