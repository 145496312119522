import React from "react";
import { Image } from "rebass";
import styled from "styled-components";

const Img = styled(Image)`
  max-width: 100%;
  max-height: 100%;
  z-index: ${props => props.zIndex};
`;
// width: auto;

export default ({zIndex=-1, ...props}) => <Img zIndex={zIndex} {...props} />;
