import { useQuery } from '@apollo/react-hooks';
import { H2, P } from "components/Heading";
import { MoreLink } from "components/Link";
import { sanitize } from 'dompurify';
import { GET_PAGE_BY_SLUG } from 'Globals';
import parseHTML from 'html-react-parser';
import { parseOptions } from 'pages/Presse';
import React from "react";
import { Box, Flex } from "rebass";







export default function({numItems = 5, ...props}) {
  const slug = 'medienberichte' 
  const pageData = useQuery(GET_PAGE_BY_SLUG, {variables:{slug}});

  return <Flex {...props} flexDirection={["column", "row"]} justifyContent={"center"} p={4} >
    <Flex justifyContent={["flex-start", "flex-end"]} p={2}>
      <H2 mt={2} mb={0} mx={3} textAlign="right" css={{whiteSpace: 'nowrap'}}> 
        <Box as="span">Was in</Box>
        <br/>
        <Box as="span">den <P>Medien</P></Box>
        <br/>
        <Box as="span">über uns</Box>
        <br/>
        <Box as="span"><P>berichtet</P> wird</Box>
      </H2>
    </Flex>
    <Flex p={2} flexDirection="column" alignItems="flex-start" justifyContent="center">
      {!pageData.loading &&
        <Box py={2}>
          {parseHTML(sanitize(pageData.data.page.content), parseOptions(numItems))}
        </Box>
      }
      <MoreLink to="presse" mt={3}>Mehr</MoreLink>
    </Flex>

    </Flex>
}
