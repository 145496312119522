import { useLocation } from "@reach/router";
import React from "react";
import { Menu, X } from "react-feather";
import { Box, Flex } from "rebass";
import styled, { css } from "styled-components";
import DesktopOnly from "../DesktopOnly";
import Icon from "../Icon";
import Layout from "../Layout";
import Link from "../Link";
import MobileOnly from "../MobileOnly";
import Text from "../Text";
import { createToggle } from "../Toggle";
import IsScrolled from "../WithIsScrolled";
import bg from './bg.svg';
import Logo from "./Logo";


const { Toggle, State, Display } = createToggle("mobile-menu");

const MobileMenu = styled(Flex)`
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
  width: 100%;
  height: 100%;
`;

const NavText= styled(Text)`

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`

const NavItemWrapper = styled(Box)`
  border-bottom: 3px solid transparent;
  &:hover {
    border-bottom: 3px solid ${props => props.theme.colors.primary};
  }
  ${props => props.active && `border-bottom: 3px solid ${props.theme.colors.primary}`};

  &:last-of-type {
    margin-right: 0;
  }
`

const NavItem = (props => {
  const location = useLocation();
  const active = location.pathname.indexOf(props.href) === 0;
  return (
    <NavItemWrapper mx={1} className={props.className}
      py={2}
      px={2}
      active={active}
    >
      <Link itemProp="url" href={props.href} underline={false}>
        <NavText
          fontSize={[2, 2, 3]}
          color={active ? "primary" : "secondary"}
          fontWeight={active ? "bold" : "normal"}
          itemProp="name"
        >
          {props.title}
        </NavText>
      </Link>
    </NavItemWrapper>
  );
})

const MobileNavItem = ({indent=true, small=false, ...props}) => (
  <Box px={indent ? 5 : 3} py={small ? 2 : 3} mt={1}  onClick={props.onClick}>
    <Link href={props.href}>
      <Text color="secondary" as="div" fontSize={small ? 1: 3} fontWeight="bold">
        {props.title}
      </Text>
    </Link>
  </Box>
);

const Wrapper = styled(Flex).attrs({
  as: "nav"
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.background};
  z-index: 9;
  transition: background 250ms ease-in-out, box-shadow 250ms ease-in-out;


  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 0px;
  }


  ${props =>
    false && props.isScrolled &&
    css`
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 4px 0px;
    `};
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

class Nav extends React.Component<{}> {
  menu: ?HTMLInputElement;

  closeMenu = () => {
    if (this.menu) this.menu.checked = false;
  };

  render() {
    return (
      <IsScrolled>
        {({ isScrolled }) => (
          <>
            <Wrapper isScrolled={isScrolled} py={4} >
              <Layout py={1} width={1} wide={true} >
                <Flex
                  alignItems="center"
                  justifyContent={["space-between"]}
                >
                  <Logo p={2} />
                  <DesktopOnly
                    itemScope
                    itemType="http://www.schema.org/SiteNavigationElement"
                  >
                    <NavItem href="/ueber-uns" title="Über uns" />
                    <NavItem href="/stadtarbeit" title="Stadtarbeit" />
                    <NavItem href="/stadtforschung" title="Stadtforschung" />
                    <NavItem href="/projekte" title="Projekte" />
                    <NavItem href="/termine" title="Termine" />
                    <NavItem href="/presse" title="Presse/Doku" />
                    <NavItem href="/en" title="EN" />
                  </DesktopOnly>
                </Flex>
              </Layout>
            </Wrapper>
            <MobileOnly
              css={{
                position: "fixed",
                top: "21px",
                right: "16px",
                zIndex: 10
              }}
            >
              <Toggle>
                <Icon>
                  <Menu style={{ verticalAlign: "bottom" }} />
                </Icon>
              </Toggle>
            </MobileOnly>
            <MobileOnly>
              <State ref={elem => (this.menu = elem)} />
              <Display
                css={{
                  position: "fixed",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 11
                }}
              >
                <Toggle>
                  <Icon
                    css={{
                      position: "absolute",
                      right: "16px",
                      top: "21px"
                    }}
                  >
                    <X style={{ verticalAlign: "bottom" }} />
                  </Icon>
                </Toggle>
                <MobileMenu flexDirection="column">
                  <MobileNavItem
                    href="/"
                    indent={false}
                    title={
                      <Logo />
                    }
                    onClick={this.closeMenu}
                  />
                  <MobileNavItem
                    href="/ueber-uns"
                    onClick={this.closeMenu}
                    title="Über uns"
                  />
                  <MobileNavItem
                    href="/stadtarbeit"
                    onClick={this.closeMenu}
                    title="Stadtarbeit"
                  />
                  <MobileNavItem
                    href="/stadtforschung"
                    onClick={this.closeMenu}
                    title="Stadtforschung"
                  />
                  <MobileNavItem
                    href="/projekte"
                    title="Projekte"
                    onClick={this.closeMenu}
                  />
                  <MobileNavItem
                    href="/termine"
                    title="Termine"
                    onClick={this.closeMenu}
                  />
                  <MobileNavItem
                          href="/presse"
                          title="Presse/Doku"
                          onClick={this.closeMenu}
                        /> 
                  <MobileNavItem
                          href="/impressum"
                          title="Impressum"
                          small={true}
                          onClick={this.closeMenu}
                        /> 
                  <MobileNavItem
                          href="/datenschutz"
                          title="Datenschutzhinweise"
                          small={true}
                          onClick={this.closeMenu}
                        /> 
                  <MobileNavItem
                          href="/en"
                          title="EN"
                          small={false}
                          onClick={this.closeMenu}
                        /> 
          {/*
                  <MobileNavItem
                    href="https://twitter.com/mxstbr"
                    onClick={this.closeMenu}
                    title={
                      <>
                        @mxstbr{" "}
                        <Icon ml={1}>
                          <Twitter size="1em" />
                        </Icon>
                      </>
                    }
                  />
                */}
                </MobileMenu>
              </Display>
            </MobileOnly>
          </>
        )}
      </IsScrolled>
    );
  }
}

export default Nav;
