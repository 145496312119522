import React from 'react'
import ContentLoader from 'react-content-loader'

const Grid = props => {
  return (
    <ContentLoader height={450} width={900} {...props}>
      <rect x="10" y="10" rx="5" ry="5" width="460" height="240" />
      <rect x="480" y="10" rx="5" ry="5" width="460" height="380" />
      <rect x="10" y="160" rx="5" ry="5" width="460" height="380" />
      <rect x="480" y="300" rx="5" ry="5" width="460" height="240" />
    </ContentLoader>
  )
}

export default Grid
