import { ListDivider } from "components/Lists";
import Text from "components/Text";
import { format, parseISO } from "Globals";
import React from "react";
import { Box, Flex } from "rebass";
import { css } from "styled-components";



const PressListItem = ({ item, children, last=false}) => (
  <Box
    mt={2}
    pb={2}
    Bcss={css`
      width: 100%;
      display: block;
    `}
  > 
    <Flex flexDirection={["column", "row"]} alignItems={["flex-start"]} justifyContent={["flex-start"]} mb={2}>
      <Text>
        <Box as="span" fontSize={2} mr={2} fontWeight="bold">
          {item.press}
        </Box>
        <Box as="span"
          color="tertiary"
        >
          {`${format(parseISO(item.date), "d. MMM yyyy").toUpperCase()}`}
        </Box>
      </Text>
    </Flex>
    {children}
    <ListDivider />
  </Box>
);
export default PressListItem;
