import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import Blockquote from "./Blockquote";
import Divider from "./Divider";
import Heading, { H2, H3, H4 } from "./Heading";
import Iframe from "./Iframe";
import Image from "./Image";
import Link from "./Link";
import Paragraph from "./Paragraph";


const Code = styled(Box).attrs(props => ({
  as: "code",
  fontSize: props.fontSize || "15px"
}))`
  line-height: 1.4;
  font-family: Inconsolata, Monaco, monospace;
  color: ${props => props.theme.colors.text};
  border-radius: 3px;
`;

const InlineCode = styled(Code).attrs({
  fontSize: 2
})`
  background-color: ${props => props.theme.colors.greys[0]};
  ${"" /* border: 1px solid ${props => props.theme.colors.greys[1]}; */} padding: 0 0.25em;
`;

const Pre = styled(Box).attrs({
  as: "pre",
  p: 3,
  mb: 3,
  fontSize: "15px"
})`
  display: block;
  white-space: pre;
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #edeff1;
  border-radius: 3px;
`;


const Em = styled.em`
  font-style: italic;
`;
const Sub = styled.sub`
  font-style: italic;
`;
const Sup = styled.sup`
  font-style: italic;
`;
const Span = styled(Box).attrs({
  as: "span",
})`
  &.has-inline-color {
    color: ${props => props.theme.colors.primary} !important;
  }
`
const Mark = styled(Box).attrs({
  as: "mark",
})`
  &.has-inline-color {
    color: ${props => props.theme.colors.primary} !important;
  }
`

const Figure = styled(Box).attrs({
  as: "figure",
})`

  &.wp-block-pullquote {
    font-size: 0.6rem;
  }
`

const Strong = styled.strong`
  font-weight: bold;
`;

const Br = (props) => <Box as="br" {...props} />

const Div = styled.div`
`

const Div2 = styled.div`
  &.wp-block-columns {
    display: grid;
    column-gap: ${props => props.columnGap || "0.8rem"};
    grid-gap: 30px;
  }
  &.wp-block-columns > * {
    grid-column: 1 / -1;
    margin: 0 !important;
  }
  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    &.wp-block-columns {
      grid-template-columns: repeat(12, 1fr);
    }

    &.wp-block-column {
      grid-column-start: auto;
      grid-column-end: span 6;
    }

    &.wp-block-column:only-child {
      grid-column-end: span 12;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    /* Three column layout */
    &.wp-block-column:not([style*='flex-basis']):nth-last-child(3):first-child,
    &.wp-block-column:not([style*='flex-basis']):nth-last-child(3):first-child
      ~ .wp-block-column {
      grid-column-end: span 4;
    }

    /* "25 / 50 / 25" layout */
    &.wp-block-column[style*='25%'] {
      grid-column-end: span 3;
    }
    &.wp-block-column[style*='50%'] {
      grid-column-end: span 6;
    }

    /* "30 / 70" and "70 / 30" layouts */
    &.wp-block-column[style*='33.33%'] {
      grid-column-end: span 4;
    }
    &.wp-block-column[style*='66.66%'] {
      grid-column-end: span 8;
    }
  }

`;
//https://dbushell.com/2020/11/02/wordpress-gutenberg-columns-block-css-styles/

const Figcaption = styled(Text).attrs({
  as: "figcaption",
})`
`
const Ul = styled.ul`
`;
const Li = styled.li`
`;
const Ol = styled.ol`
`;

const H1 = styled(Heading).attrs({
  pb: 2
})`
  line-height: 0.8;
`


const Audio = styled.audio`
`

export default {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: (props) => <H4 fontSize="18px" {...props} />,
  p: (props) => <Paragraph {...props} />,
  a: Link,
  ul: Ul, //UnorderedList,
  ol: Ol, //OrderedList,
  li: Li, //(props: {||}) => <ListItem {...props} />,
  img: Image,
  pre: Pre,
  mark: Mark,
  code: Code,
  inlineCode: InlineCode,
  em: Em,
  blockquote: props => <Blockquote {...props} />,
  strong: Strong,
  br: Br,
  iframe: Iframe,
  div: Div,
  sub: Sub,
  sup: Sup,
  span: Span,
  figure: Figure,
  figcaption: Figcaption,
  audio: Audio,
  hr: (props) => <Divider as="hr" mb={[2, 3]} />,
};
