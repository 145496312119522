import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import Link from "./Link";
import Text from "./Text";


  //max-width: 35em;
const Wrapper = styled(Box)`
  width: ${props => props.width};
  position: relative;
  z-index: 1;

  ${props => `column-count: ${props.columns || 1};`} 
  @media screen and (max-width: ${props => props.theme.breakpoints[0]}) {
    column-count: 1;
  }


  ${props => `column-gap: ${props.theme.columns.gap};`} 

  ${props => props.centered && `margin: 0 auto;`} 

  ${Link} {
    color: ${props => props.theme.colors.primary};
  }
`;

export default ({ fontWeight, centered, columns, width="100%",  as, ...props }) => (
  <Wrapper centered={centered} columns={columns} width={width} as={as}>
    <Text color="secondary" mb={3} fontSize={[2, 3]} fontWeight={fontWeight} lineHeight={1.6} textAlign={centered ? "center": "left"} {...props} />
  </Wrapper>
);
