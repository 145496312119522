const to = `/wohnstrassenleben-rueckblick`

const redirects = {
"wohnstrassenleben-urbanize":to,
"waehringer-wohnstrassenleben":to,
"wohnstrassenleben-9":to,
"wohnstrassenleben-10":to,
"wohnstrassenleben-8":to,
"wohnstrassenleben-dritter-tag-der-wohnstrasse":to,
"wohnstrassenleben-6":to,
"wohnstrassenleben-4":to,
"qr-schnitzeljagd":to,
"wohnstrassenleben-2":to,
"projekt-garderobe-kleidertausch":to,
"wohnstrassenleben-3":to,
"wohnstrassenleben-5":to,
"beweg-dich-und-qr-schnitzeljagd-3":to,
"wohnstrassenleben-11":to,
"beweg-dich-und-qr-schnitzeljagd-2":to,
"beweg-dich-und-qr-schnitzeljagd":to,
"tag-der-wohnstrasse-2":to,
"beweg-dich-5":to,
"wohnstrassenleben-14":to,
"wohnstrassenleben-12":to,
"wohnstrassenleben-spiel-im-schubertpark":to,
"wohnstrassenleben-13":to,
"reck-dich-lab-dich-streck-dich":to,
"wohnstrassenleben-am-diepoldplatz":to,
"meeting-auf-der-wohnstrasse":to,
"tag-der-wohnstrasse":to,
"wohnstrassen-tafeln":to,
"wohnstrassenleben-15":to,
"wohnstrassenleben-16":to,
"wohnstrassenleben-17":to,
}

export default redirects
