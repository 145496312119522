import { useQuery } from '@apollo/react-hooks';
import HeaderToggle from "components/HeaderToggle";
import Heading from "components/Heading";
import Page from "components/Page";
import { compareAsc, compareDesc, isAfter, isBefore } from "date-fns";
import { GET_PAGES_BY_CATEGORY, parseISO } from "Globals";
import Contact from "modules/Contact";
import InstagramEmbed from "modules/InstagramEmbed";
import React, { useState } from "react";
import { Box } from "rebass";
import EventListItem from "./EventListItem";
import Loader from "./Loader";






const PAGES = {
  'Kommende Termine': false,
  'Archiv': true
}

export default function TerminePage (props) {
  const [archive, setArchive] = useState(false);
  const pages = useQuery(GET_PAGES_BY_CATEGORY, {variables:{category: 'Termine', first: 1000}});
  let events = []
  if (!pages.loading) {
    const pageNodes = pages.data.pages.edges
      .map(e => e.node)

    const archiveEvents = pageNodes
      .filter(p => isAfter(new Date(), parseISO(p.eventEnd)))
      .sort((a, b) => compareAsc(parseISO(b.eventEnd), parseISO(a.eventEnd)))
    const upcomingEvents = pageNodes
      .filter(p => isBefore(new Date(), parseISO(p.eventEnd)))
      .sort((a, b) => compareDesc(parseISO(b.eventEnd), parseISO(a.eventEnd)))

    events = archive ? archiveEvents : upcomingEvents;
  }

  return (
        <Page>
      <Heading >
        <Box as="span" color="primary">Termine</Box>
        <br/>
        <Box as="span" pr={80}>& Aktivitäten</Box>
      </Heading>

      <HeaderToggle my={3} items={Object.keys(PAGES)}  onSelect={e => setArchive(PAGES[e])} />
      {pages.loading ? <Loader /> :
        <>
        {events.map((evt, index) => (
          <EventListItem
            key={`${evt.title}_${index}`}
            last={ index === events.length - 1}
            evt={evt}
          />
        ))}

        </>
      }

      <Contact />
      <InstagramEmbed />
    </Page>
  )
}
