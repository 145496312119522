import { createControlComponent } from "@react-leaflet/core";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from "react";
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet';


const CENTER = [48.2, 16.366667]

export const GeosearchControl = createControlComponent(
  (props) => {
  const provider = new OpenStreetMapProvider();

  const searchControl = new GeoSearchControl({
    provider: provider,
    style: 'bar', // optional: bar|button  - default button
    autoComplete: true, // optional: true|false  - default true
    autoCompleteDelay: 250, // optional: number      - default 250
  });
  return searchControl
  }
);

export default (props) => {

  const map = useRef()

  const [data, setData] = useState(null);

  useEffect(async () => {
    const response = await fetch(
      'https://data.wien.gv.at/daten/geo?service=WFS&request=GetFeature&version=1.1.0&typeName=ogdwien:WOHNSTRASSEOGD&srsName=EPSG:4326&outputFormat=json'
    );

    const data = await response.json()
    setData(data);
  }, []);


  return (
      <MapContainer
        center={CENTER}
        zoom={13}
        zoomControl={true}
        style={{height: "100%",zIndex: 0}}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        {data &&
          <GeoJSON
            attribution="Stadt Wien"
            data={data}
            onEachFeature={(feature, layer) => {
              if (feature.properties) {
        layer.bindPopup(`<h5>${feature?.properties?.ADRESSE}</h5>`);

      }
            }}
          />
        }
      <GeosearchControl />
      </MapContainer>

  )
};
