import WohnstrassenMap from "components/Maps/Wohnstrassen";
import Page from "components/Page";
import React from "react";
import { Box } from "rebass";


export default function TestPage (props) {
  return (
        <Page>

    <Box css={{height: "500px"}}>
        <WohnstrassenMap />
    </Box>
    </Page>
  )
}
