import { useLocation } from "@reach/router";
import Page from "components/Page";
import WordpressContent from "modules/WordpressContent";
import React from "react";

export default function LegacyPage(props) {

  const location = useLocation();
  const {pathname, href} = location;
  let slug = ''
  if (href !== pathname) {
    slug = pathname.slice(1)
  }
  return (
    <Page>
      <WordpressContent slug={slug} />
    </Page>
  );
}
