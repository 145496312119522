import React from "react";
import Helmet from "react-helmet";

export default ({title, description}) => {
  return (
    <Helmet>
    {title != undefined && <title key="title">{title}</title>}
      {description != undefined && (
        <meta name="description" key="description" content={description} />
      )}
    </Helmet>
  )
}
