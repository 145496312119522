import React from "react";
import { Box, Heading } from "rebass";

const BaseHeading = ({textAlign=["left", "center"], ...props}) => (
  <Heading
    as="h1"
    lineHeight={1}
    fontSize={[6,8]}
    fontFamily="Zilla Slab"
    fontWeight="400"
    textAlign={textAlign} 
    {...props}
  />
);

const H2 = ({textAlign=["left", "center"], ...props}) => (
  <BaseHeading fontSize={[5,7]} as="h2" mb={4} mt={5} {...props} />
);
const H3 = ({textAlign=["left", "center"], ...props}) => (
  <BaseHeading fontSize={[4,5]} as="h3" mb={3} mt={4} {...props} />
);
const H4 = ({textAlign=["left", "center"], ...props}) => (
  <BaseHeading fontSize={[3,4]} as="h4" mb={3} mt={3} textAlign={textAlign} {...props} />
);

const P = (props) => (
  <Box as="span" color="primary" {...props} />
)

export { H2, H3, H4, P };

export default BaseHeading;
