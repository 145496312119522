import React, { useState } from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import BreadcrumbLink from "./BreadcrumbLink";
import Text from "./Text";

const ToggleItemWrapper = styled(Box)`
  border-bottom: 2px solid transparent;
  &:hover {
    border-bottom: 2px solid ${props => props.theme.colors.secondary};
  }
  ${props => props.active && `border-bottom: 2px solid ${props.theme.colors.secondary}`};
`

const ToggleItem = ({active, onClick, ...props}) => (
    <ToggleItemWrapper ml={2} mr={3} 
      py={2} onClick={onClick} active={active}
    >
      <BreadcrumbLink {...props} >
        <Text as="div" fontSize={[1, 2]}
            color={active ? "#666" : "#888"}
            fontWeight={active ? "bold" : "normal"}
        >
          {props.children}
        </Text>
      </BreadcrumbLink>
  </ToggleItemWrapper>
)

const HeaderToggle = ({items=[false], ...props}) => {
  const [active, setActive] = useState(items[0]);
  return (
  <Flex justifyContent="center" {...props}>
    {items.map((item,i) => {
      return <ToggleItem {...props} active={item === active} onClick={_ => {
        setActive(item)
        props.onSelect(item);
      }}>
        {item}
      </ToggleItem>
    })}
  </Flex>
  )
};

export default HeaderToggle;
