import { Box } from "rebass";
import styled from "styled-components";

const Divider= styled(Box).attrs({
  as: 'hr'
})`
  color: rgba(0,0,0,0.1);
`;

export default Divider;
