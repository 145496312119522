import { H2, P } from "components/Heading";
import Link from "components/Link";
import React from "react";
import { Box } from "rebass";



export default function InstagramEmbed (props) {
  return <Box {...props}>
      <H2 textAlign={["left", "center"]} py={2}>
        <Box as="span" pl={[0, 5]}>Folge uns auf</Box>
        <br />
        <Box as="span" pl={[0, 0]}><P>instagram</P> <P><Link href="//instagram.com/spaceandplace_at"><Box as="span" pl={4} fontSize={[3, 4]}>@spaceandplace_at</Box></Link></P></Box>
      </H2>
    </Box>
}
