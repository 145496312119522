import { H2 } from "components/Heading";
import Image from "components/Image";
import Subtitle from "components/Subtitle";
import React, { useRef, useEffect, useState } from "react";
import { Box, Flex } from "rebass";
import { Wrapper, Status } from "@googlemaps/react-wrapper";


const center = { lat: 48.2027275, lng: 16.3273057 };
const zoom = 17;
const apiKey = "AIzaSyBVojBhA-uqn-PHiWb-cy3oXkKytMvK77k"

const render = (status) => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>;
  if (status === Status.FAILURE) return <h3>{status} ...</h3>;
  return null;
};

function MyMapComponent({ center, zoom, }) {
  const ref = useRef();

  useEffect(() => {
    new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
  });

  return <div ref={ref} style={{ height: "500px" }} id="map" />;
}

const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};


export default function({ wide = false, ...props }) {
  return <Box>
    <Wrapper apiKey={apiKey} render={render}>
      <MyMapComponent center={center} zoom={zoom} >
        <Marker position={center} />/
      </MyMapComponent>

    </Wrapper>
  </Box>
}
