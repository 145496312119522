import { useQuery } from '@apollo/react-hooks';
import HeaderToggle from "components/HeaderToggle";
import Heading, { P } from "components/Heading";
import Page from "components/Page";
import WideSection from "components/WideSection";
import { compareAsc } from "date-fns";
import { GET_PAGES_BY_CATEGORY, parseISO, URL_PREFIX } from "Globals";
import ProjectIdea from "modules/ProjectIdea";
import React, { useState } from "react";
import { Box } from "rebass";
import styled from "styled-components";
import Loader from "./Loader";
import ProjectCard from "./ProjectCard";







const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 1rem;
  justify-items: stretch;
  @media (min-width: ${props => props.theme.breakpoints[0]}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: ${props => props.theme.breakpoints[1]}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`

const PAGES = {
  'Aktuelle Projekte': false,
  'Vergangene Projekte': true
}
export default function ProjektePage (props) {
  const [archive, setArchive] = useState(false);

  const category = archive ? 'projektarchiv' : 'aktuelle-projekte';
  const pages = useQuery(GET_PAGES_BY_CATEGORY, {variables:{category, first: 100}});
  let projects = []
  if (!pages.loading) {
    const pageNodes = pages.data.pages.edges.map(e => e.node)
      .sort((a, b) => compareAsc(parseISO(b.eventEnd), parseISO(a.eventEnd)))

    /*
    const archiveProjects = pageNodes.filter(p => isAfter(new Date(), parseISO(p.eventEnd)))
    const actualProjects = pageNodes.filter(p => isBefore(new Date(), parseISO(p.eventEnd)))
    console.log('e', actualProjects)
    console.log('a', archiveProjects)
    projects = archive ? archiveProjects: actualProjects;
    */
    projects = pageNodes;
  }

  return (
        <Page>
      <Heading>
        <Box as="span" pl={[0,6]}>Das zeichnet</Box>
        <br/>
        <Box as="span" pl={[0,-2]}>unsere <P>Projekte</P> aus.</Box>
      </Heading>

      <HeaderToggle my={3} items={Object.keys(PAGES)}  onSelect={e => setArchive(PAGES[e])} />
      {pages.loading ? <Loader /> :
        <Grid>
          {projects.map((project, index) => (
                <ProjectCard
                  key={project.slug}
                  link={`${project.slug}`}
                >
                  <ProjectCard.Image 
                    src={`${URL_PREFIX}${project.headerimage}`}
                  />
                  <ProjectCard.Title pt={3}>{project.title}</ProjectCard.Title>
                </ProjectCard>
          ))}
        </Grid>
      }

    <WideSection>
      <ProjectIdea backgroundColor="backgroundSecondary" />
    </WideSection>
    </Page>
  )
}
