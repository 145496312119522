import { format as dateFormat, parseISO as dateParseISO, sub } from 'date-fns';
import { de } from 'date-fns/locale';
import gql from "graphql-tag";

export function parseISO (date) {
  return dateParseISO(date.replace('+00:00', '')) // remove timzeone info
}
export function format (date, formatStr = 'PP') {
  return dateFormat(date, formatStr, {
    locale: de
  })
}

export const URL_PREFIX = "https://spaceandplace.at/cms/wp-content/uploads/"

const PAGE_ATTRIBUTES = `
id
databaseId
title
status
headerimage
eventStart
eventEnd
link
date
uri
slug
template {
  templateName
}
`

export const GET_PAGE_BY_SLUG= gql`
query GET_PAGE($slug: ID!) {
    page(id: $slug, idType: URI) {
        ${PAGE_ATTRIBUTES}
        content
  }
}
`
export const GET_PAGE_META_BY_SLUG = gql`
query GET_PAGE($slug: String) { 
  pages(where: {metaQuery: {
  relation: OR, 
  metaArray: [
    {key: "custom_permalink", value: $slug, compare: EQUAL_TO}
    ]
  }}
  ) 
  {
    edges {
      cursor
      node {
        ${PAGE_ATTRIBUTES}
        content
      }
    }
  }
}
`

export const GET_PAGES_BY_CATEGORY = gql`
query GET_PAGES_BY_CATEGORY($first: Int, $category: String) {
  pages(
    first: $first,
    where: {
      taxQuery: {
        taxArray: [
          {
            terms: [$category],
            taxonomy: CATEGORY,
            operator: IN,
            field: SLUG
          },
        ]
      }
      }
  ){
    edges{
      cursor
      node{
        ${PAGE_ATTRIBUTES}
      }
    }
  }
}
`

