import Image from "components/Image";
import Text from "components/Text";
import React from "react";
import {
    Card, Flex, Link
} from "rebass";
import styled from "styled-components";


const Img = props => <Image {...props} zIndex={0} 
borderRadius='0.5em'
css={`
transition: all 0.3s ease 0s;
  &:hover {  transform: scale(1.03);}
  `}
  />;
const TitleText= styled(Text)`
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`
const Title = props => <TitleText {...props} fontWeight={700} fontSize={2} lineHeight={1} my={0} />;
const Description = props => (
  <Text color="#63c132" fontSize={2} lineHeight={1.25} {...props} mb={4} mt={2} />
);
const FinePrint = props => (
  <Text as="div" color="#666" fontSize={1} {...props} />
);

const Wrapper = styled(Link).attrs({
  //m: [1, 2],
  //mb: 2
  p: [1,2]
})`
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
`;

const BaseCard = styled(Card)`
  border-radius: 5px;
  background-color: ${props => props.theme.colors.background};
  height: 100%;
`;


const TeamCard = (props: RebassProps) => {

    const { link, children } = props;

    return (
      <Wrapper href={`${link}`} >
        <BaseCard >
          <Flex flexDirection="column" justifyContent="center"
      css={{height: "100%"}}
      >
            {children}
          </Flex>
        </BaseCard>
      </Wrapper>
    );
}

TeamCard.Image = Img;
TeamCard.Title = Title;
TeamCard.Description = Description;

export default TeamCard;
