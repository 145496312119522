import Head from "components/Head";
import Heading, { H2, P } from "components/Heading";
import Image from "components/Image";
import { MoreLink } from "components/Link";
import Page from "components/Page";
import Paragraph from "components/Paragraph";
import Subtitle from "components/Subtitle";
import Text from "components/Text";
import WideSection from "components/WideSection";
import Contact from "modules/Contact";
import InTheMedia from "modules/InTheMedia";
import PageModules from "modules/Page";
import React from "react";
import { Box, Flex } from "rebass";
import stadtarbeitLeftImage from './stadtarbeitLeft.jpg';
import stadtarbeitRightImage from './stadtarbeitRight.jpg';
import stadtforschungImage from './stadtforschung.jpg';
import topImage from './top.jpg';



const SectionTitle = ({ to, children, ...props }) => (
  <Text {...props} textAlign={["left", "center"]} mt={4} color="tertiary" fontSize={[2, 3]} css={{ textTransform: 'uppercase', letterSpacing: '0.2em' }}>{children}</Text>
)


const H = ({ children, ...props }) => (
  <H2 textAlign={["left", "left"]} mt={0}>
    {children}
  </H2>
)


export default function HomePage(props) {
  return (
    <Page wide={true}>
      <Head
        title={`space and place`}
        description={`space and place`}
      />
      <Heading pl={[0, 10]} css={{ textAlign: "left" }} fontSize={[6, '92px']} mt={[0, -3]}>
        <Box as="span"><P>Wir sind</P> space</Box>
        <br />
        <Box as="span" pl={[0, 300]}>and <Box as="span" fontWeight="700">place</Box></Box>
      </Heading>
      <Subtitle width={["auto", '45%']} textAlign={["left", "right"]} mt={[1, -40]} ml={[0, 100]}>
        space and place <br /> schafft Bewegendes <br /> im urbanen Raum.
    </Subtitle>
      <Flex mt={[-2, -4]} alignItems="flex-start">
        <Image src={topImage} width="auto" height="auto" />
      </Flex>
      <Flex flexDirection="column" justifyContent={['flex-start', 'center']}>
        <H2 textAlign={["left", "center"]}>
          <Box as="span" color="primary">#stadtbegegnen</Box>
        </H2>
        <Paragraph fontWeight="700" width="70%" centered={true}>
          Wir gehen mit Kunst und Kreativität auf die Stadt zu.
          Dabei arbeiten wir progressiv, forschend und
          vernetzend. Wir verstehen uns als Stadtarbeiter*innen
          und Stadtforscher*innen mit einem Blick für das Verborgene.
          space and place entwickelt Prototypen urbaner Nutzung.
          <MoreLink to="ueber-uns" mt={3} >Mehr über uns erfahren</MoreLink>
        </Paragraph>
      </Flex>
      <Flex flexDirection="column" mt={2}>
        <SectionTitle>Stadtarbeit</SectionTitle>
        <H>
          <Box as="span" pl={[0, 300]}>Öffentliche</Box>
          <br />
          <Box as="span" pl={[0, 0]}><P>Wohnzimmer</P></Box>
          <br />
          <Box as="span" pr={[0, 300]}>für alle</Box>
          <br />
          <Box as="span" pl={[0, 200]}>gestaltend</Box>
        </H>
        <Subtitle width={["auto", '100%']} mt={[0, -150]} ml={[0, '50%']}>
          Im Austausch mit Dritten schaffen wir
soziale Räume und <br />
ermöglichen so Miteinander statt Nebeneinander in der Stadt. <br />
Wir sind ein Design Labor für neues Stadtleben.
      </Subtitle>
        <Flex justifyContent="space-between" flexWrap={["wrap", "nowrap"]} alignItems="flex-start">
          <Image src={stadtarbeitLeftImage} width={["auto", 350, 450]} pr={[0, 3, 0]} mt={[-2, -6]} />
          <Image src={stadtarbeitRightImage} width={["auto", 400, 500]} pl={[0, 4, 0]} mt={[0, -5]} />
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt={2}>
        <SectionTitle pr={[0, 150]}>Stadtforschung</SectionTitle>
        <H>
          <Box as="span" pr={[0, 200]}>Mit einem</Box>
          <br />
          <Box as="span" pr={[0, 50]}><P>forschenden</P></Box>
          <br />
          <Box as="span" pl={[0, 0]}>Blick</Box>
          <br />
          <Box as="span" pr={[0, 0]}>auf <P>die Stadt</P></Box>
        </H>
        <Subtitle width={["auto", '60%']} textAlign={["left", "right"]} mt={[0, -150]} mr={[0, '35%']}>
          IN UNSERER STADTFORSCHUNG WENDEN WIR <br />
DIFFERENZIERTE SICHTWEISEN AUF ALLTÄGLICHES AN.<br />
WIR BEOBACHTEN, REFLEKTIEREN UND SPIELEN ZURÜCK.
    </Subtitle>
        <Flex justifyContent="center" alignItems="flex-start">
          <Image src={stadtforschungImage} width="auto" height="auto" />
        </Flex>
      </Flex>
      <Flex flexDirection="column" mt={2}>
        <SectionTitle>Projekte</SectionTitle>
        <H>
          <Box as="span" pl={[0, 250]}>Mutig</Box>
          <br />
          <Box as="span" pl={[0, 150]}><Box as="span" color="primary">innovativ.</Box></Box>
        </H>
        <Flex justifyContent={["flex-start", "flex-end"]}>
          <Paragraph width="70%">
            Wir begeben uns an oftmals unscheinbare Orte
            und erwecken sie gemeinsam mit Anrainer*innen,
            lokalen Initiativen und Künstler*innen zum Leben.
            Durch unsere kreativen und sozio-kulturellen
            Projekte und Aktivitäten ermöglichen wir #stadtbegegnen.
        </Paragraph>
        </Flex>
      </Flex>
      {/*
        <ul>
              <li>
                <Link to="/whoosh">Page - whoosh</Link>
              </li>
              <li>
                <Link to="/projekte">Projekte</Link>
              </li>
              <li>
                <Link to="/3764-2">Page - 3764-2 kein permalink</Link>
              </li>
              <li>
                <Link to="/mitten-in-meidling">Page - mitten in meidling 4389 - Gallery</Link>
              </li>
              <li>
                <Link to="/kinderradspass-und-zusammenkommen-auf-der-mandlgasse">Page - Redirect to mitten in meidling - Gallery</Link>
              </li>
              <li>
                <Link to="/medien/wohnstrassen">Page - Medien/Wohsntrassen</Link>
              </li>
              <li>
                <Link to="/cms/wp-admin">WP-Admin</Link>
              </li>
            </ul>
            */}


      <PageModules.SpecialTeaser
        heading={_ =>
          <H2>
            <Box as="span">Was in</Box>
            <br />
            <Box as="span">den <Box as="span" color="primary">Medien</Box></Box>
            <br />
            <Box as="span">über uns</Box>
            <br />
            <Box as="span"><Box as="span" color="primary">gesagt</Box> wird</Box>
          </H2>
        }
        text={_ =>
          <Paragraph>
            asdf
            </Paragraph>
        }
      />
      <WideSection>
        <InTheMedia backgroundColor="backgroundSecondary" />
      </WideSection>
      <Contact wide={true} />
    </Page>
  )
}
