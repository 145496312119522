import { useQuery } from '@apollo/react-hooks';
import Divider from "components/Divider";
import { H3, H4, P } from "components/Heading";
import Link from "components/Link";
import Paragraph from "components/Paragraph";
import Text from "components/Text";
import { GET_PAGE_BY_SLUG, URL_PREFIX } from 'Globals';
import parseHTML from 'html-react-parser';
import { parseOptions } from 'modules/WordpressContent';
import React from "react";
import { Box, Flex } from "rebass";




export default function({ showPhotos = true, ...props }) {
  const pageData = useQuery(GET_PAGE_BY_SLUG, { variables: { slug: 'pressefotos' } });
  const items = [
    { title: `space and place 365/2023`, href: `space-and-place_doku2023_ausaufderspur.pdf`, type: 'PDF' },
    { title: `space and place 365/2022`, href: `doku_schwing2022_RZ.pdf`, type: 'PDF' },
    { title: `space and place 365/2021`, href: `space_and_place-jahresdoku2021.pdf`, type: 'PDF' },
    { title: `space and place 366/2020`, href: `Jahresbericht2020-web-sp366-2020_A5_16S.pdf`, type: 'PDF' },
    { title: `space and place 365/2019`, href: `365.2019_web-doku_spaceandplace.at_.pdf`, type: 'PDF' },
    { title: `space and place 365/2018`, href: `web-Sp365_2018.pdf`, type: 'PDF' },
  ]
  return <Box {...props} px={[4, 0]}>
    <Flex {...props} flexDirection={["column", "row"]} justifyContent="center" alignItems="flex-start">
      <Box p={2}>
        <H3 mt={4} mb={0}>
          <P>Download</P>
          <br />
          <Box as="span" pr={2}>Jahresdoku</Box>
        </H3>
      </Box>
      <Flex p={2} flexDirection="column" alignItems="flex-start" justifyContent="center">
        {items.map(item => (
          <>
            <H4>{item.title}</H4>
            <Text color="primary" pb={1}><Link href={`${URL_PREFIX}${item.href}`}>{`Download ${item.type} ____`}</Link></Text>
            <Divider />
          </>
        ))}
        <Divider />
        <H4 color="primary"><Link href="/lagerraum">zum Archiv</Link></H4>
      </Flex>

    </Flex>
    {showPhotos && <>
      <Box px={[0, 5]}>
        <H3>Fotos </H3>
        <Paragraph centered={true}> frei zum Druck im Rahmen der Berichterstattung über space and place.</Paragraph>
        {!pageData.loading &&
          <Box >
            {parseHTML(pageData.data.page.content, parseOptions())}
          </Box>
        }
      </Box>
    </>
    }

  </Box>
}
