import Image from "components/Image";
import ImageLoader from "components/ImageLoader";
import Text from "components/Text";
import React, { useState } from "react";
import {
    Card, Flex, Link
} from "rebass";
import styled from "styled-components";
const Img = (props) => {

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  return (
    <>
      {(!loaded || error) && 
        <ImageLoader {...props} />
      }
    <Image {...props} zIndex={0}
      borderRadius='0.5em'
      css={`
        object-fit: contain;
        object-position: center center;
        transition: all 0.3s ease 0s;
        &:hover {  transform: scale(1.03);}
        `}
        style={loaded ? {} : { display: 'none' }}
        onLoad={_ => setLoaded(true)}
        onError={_ => setError(true)}
    />
    </>
  )
}

const TitleText= styled(Text)`
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`
const Title = props => <TitleText {...props} fontWeight="bold" fontSize={2} lineHeight={1} my={0} css={{textAlign: 'left'}} />;

const Wrapper = styled(Link).attrs({
  //m: [1, 2],
  //mb: 2
  p: [1,2]
})`
  text-decoration: none;
  color: inherit;
  display: block;
`;

const BaseCard = styled(Card)`
  border-radius: 5px;
  background-color: ${props => props.theme.colors.background};
  height: 100%;
`;

const ProjectCard = (props: RebassProps) => {

    const { link, children } = props;

    return (
      <Wrapper href={`${link}`} >
        <BaseCard >
          <Flex flexDirection="column" justifyContent="center"
      >
            {children}
          </Flex>
        </BaseCard>
      </Wrapper>
    );
}

ProjectCard.Image = Img;
ProjectCard.Title = Title;

export default ProjectCard;
