import Page from "components/Page";
import Contact from "modules/Contact";
import React, { useState } from "react";


export default function NewsletterPage(props) {

  return (
    <Page>
      <Contact />
    </Page>
  )
}
