const colors = {
  white: "#FAFAFA",
  black: "#333" // #1f1b1b
};

const theme = {
  colors: {
    primary: "#63c132",
    secondary: "#333",
    tertiary: "#666",
    text: colors.black,
    foreground: colors.white,
    Bbackground: "rgb(246, 247, 248)",
    background: "rgb(255, 255, 255)",
    backgroundSecondary: "#F6F4F4",
    ...colors
  },
  breakpoints: [
    '40em', '56em', '64em',
  ], 
  layout: {
    //width: '80em',
    width: '60em',
    widthWide: '80em',
  },
  fonts: {
    system: [
      "Karla",
    ].join(", "),
    serif: ["Georgia", "serif"].join(", ")
  },
  easing: "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
  radius: "0.5em",
  columns: {
    gap: '2rem'
  }

};

export default theme;
